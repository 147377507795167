import React, {useState} from "react";

const Selectbox = ({list, set, all, value, placeholder}) => {

  const [selectoggle, setselecttoggle] = useState(false);

  const selected = list.filter(i => i.id === value)[0];

    const choose = (e, selected) =>{
      set(selected);
      setselecttoggle(!selectoggle);
    };

  const options =list.map((item) => (
    <div className="option" onClick={e => choose(e, item.id)} key={item.id}>
      <input type="radio" className="radio" id={item.slug} name="category"/>
      <label htmlFor={item.slug}>{item.name}</label>
    </div>
  ));
  return (

    <div>
        <div className="select-box" dir="rtl">
            <div className={selectoggle? "active options-container": "options-container"} dir="rtl">
            {
              options.length > 1 ? (
                all ?
                (
                  <div>
                    <div className="option" onClick={e => choose(e, 0)} key={0}>
                      <input type="radio" className="radio" id="all" name="category"/>
                      <label htmlFor="all">الكل</label>
                    </div>
                    {
                      options
                    }
                  </div>

                )
                :
                options
              )
              :
              options
            }
                          </div>

            <div className="selected" onClick={e => setselecttoggle(!selectoggle)} dir="rtl">
              {selected? selected.name: placeholder}
            </div>
          </div>
    </div>

  );

}

export default Selectbox;
