import React, { useState, useContext } from "react";
import { Input } from "reactstrap";
import { Link } from "react-router-dom";
import SearchContext from "../contexts/SearchContext";
import Selectbox from "./Selectbox";

const SearchBox = ({ services, governorates, countries }) => {
  const [, setForm] = useContext(SearchContext);
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [service, setService] = useState("");

  return (
    <div className="SearchBox">
      <div className="row">
        <div className="col-sm-6">
          <Selectbox
            list={governorates}
            set={setState}
            placeholder={"المنطقة"}
            value={state}
            all={true}
          />
        </div>
        <div className="col-sm-6">
          <Selectbox
            list={countries}
            set={setCountry}
            placeholder={"البلد"}
            value={country}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Input
            type="text"
            name="name"
            id="name"
            placeholder="اسم المكان"
            onChange={(e) => setName(e.target.value)}
            value={name}
            dir="rtl"
          />
        </div>
      </div>
      <div className="row btn-row">
        <div className="col-sm-6">
          <Link
            className="col-sm-12 btn-warning btn"
            to="search"
            onClick={() => {
              setForm({
                name,
                service,
                country,
                governorate: state,
              });
            }}
          >
            بحث
          </Link>
        </div>
        <div className="col-sm-6">
          <Selectbox
            list={services}
            set={setService}
            placeholder={"نوع الخدمة"}
            value={service}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
