import React from "react";
import FeatureCard from "./FeatureCard";
import Scrollbar from "react-scrollbars-custom";
import { perPage } from "../extra/Control.js";

const FeatureCards = ({ data, filter, toggle, services, icons, governorates }) => {

  const filtered = data.filter(
    (item) =>
      item.name.includes(filter.name ? filter.name : item.name) &&
      (parseInt(filter.service, 10) > 1
        ? parseInt(filter.service, 10)
        : parseInt(item.category, 10)) ===
        parseInt(item.category, 10) &&
      (parseInt(filter.governorate, 10) > 0
        ? parseInt(filter.governorate, 10)
        : parseInt(item.governorate, 10)) === parseInt(item.governorate, 10)
        &&
        (parseInt(filter.country, 10) > 0
          ? parseInt(filter.country, 10)
          : parseInt(item.country, 10)) === parseInt(item.country, 10)
  ).slice(0 , perPage);

  return (
    <Scrollbar
      style={{ height: "60vh" }}
      className="container"
      noScrollX={true}
      id="search"
      key="searchOB"
    >
      {
        filtered.length?

        filtered.map((item) => {
          return(
          <FeatureCard
            key={item.id}
            {...item}
            category={services.filter((x) => parseInt(x.id, 10) === parseInt(item.category, 10))[0]}
            governorate = {governorates.filter(g => (g.id === item.governorate))[0]}
            toggle={toggle}
            icons = {icons}
          />
        )})
        :
        (
          <div className="col-11 col-sm-11 feature ml-auto mr-auto text-center" >
            غير متوفر
          </div>
        )
      }
    </Scrollbar>
  );
};

export default FeatureCards;
