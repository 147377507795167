import { Switch, Route, Redirect } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Home from "../screens/Home";
import Search from "../screens/Search";
import Info from "../screens/Info";
import Contact from "../screens/Contact";
import { baseUrl, Posts } from "../extra/baseUrl";
import SearchContext from "../contexts/SearchContext";
import Blogs from "../screens/Blogs";

const Main = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [number, setNumber] = useState(0);

  const form = useState({
    name: "",
    governorate: "",
    country: "",
    service: "",
  });
  const x = 1;

  useEffect(() => {
    fetch(baseUrl + Posts)
      .then((resp) => resp.headers.get("X-WP-Total"))
      .then((total) => {
        setNumber(total);
      });
  }, [x]);

  useEffect(() => {
    let urls = [];
    for (let i = 0; i < number; i += 100) {
      urls.push(baseUrl + Posts + `&offset=${i}`);
    }
    Promise.all(urls.map((url) => fetch(url).then((resp) => resp.json())))
      .then((r) =>
        r.map((x) =>
          x.map((p) => ({
            id: p.id,
            ...p.acf,
            category: p.categories[0],
            governorate: p.governorates[0],
            country: p.countries[0],
          }))
        )
      )
      .then((d) => {
        const recieved = [];
        let dummy;
        d.map((x) => (dummy = recieved.concat(x)));
        if (dummy) {
          setData(dummy);
          setLoading(false);
        }
      });
  }, [number]);

  return (
    <div>
      <Navbar />
      <SearchContext.Provider value={form}>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route
            path="/Search"
            component={() => <Search data={data} isLoading={isLoading} />}
          />
          <Route path="/info" component={Info} />
          <Route path="/contact" component={Contact} />
          <Route path="/blogs" component={Blogs} />
          <Redirect to="/" />
        </Switch>
      </SearchContext.Provider>
      <Footer />
    </div>
  );
};

export default Main;
