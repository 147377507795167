import React, { useState } from "react";
import logo from "../extra/images/yfsLogo.png";
import { Navbar, Nav, NavItem, NavbarToggler, Collapse } from "reactstrap";
import { NavLink, Link } from "react-router-dom";

const Navigate = () => {
  const [isNavOpen, setisNavOpen] = useState(false);

  const toggleNav = () => {
    setisNavOpen(!isNavOpen);
  };

  return (
    <Navbar className="navbar-light" expand="md">
      <div className="container" style={{ zIndex: 99999 }}>
        <NavbarToggler onClick={toggleNav} />
        <NavLink to="/" className="ml-auto d-md-none" activeClassName="x">
          <img src={logo} className="nav-img" alt="Youth friendly service" />
        </NavLink>
        <Collapse isOpen={isNavOpen} navbar>
          <Nav navbar className="mr-auto">
            <NavItem>
              <NavLink
                className="nav-link"
                to="/blogs"
                activeClassName="active"
              >
                <span>التقارير</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="nav-link"
                to="/contact"
                activeClassName="active"
              >
                <span> اتصل بنا</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav-link" to="/info" activeClassName="active">
                <span>المزيد عنا</span>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
        <NavLink
          to="/"
          as={Link}
          className="ml-auto d-none d-md-block"
          activeClassName="x"
        >
          <img
            src="yfsLogo.png"
            className="nav-img"
            alt="Youth friendly service"
          />
        </NavLink>
      </div>
    </Navbar>
  );
};

export default Navigate;
