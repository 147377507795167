import React from "react";

const FeatureCard = ({ id, toggle, governorate, category, name, icons }) => {

  let icon;
  if(category || name)
     icon = icons.filter(i => i.name === category.slug)[0];
  else {
    return(
    <div className="col-11 feature card ml-auto mr-auto">
      <div className="row">
      </div>
    </div>)
  }
  return(
  <div className="col-11 feature card ml-auto mr-auto" onClick={(e) => toggle(e, id)}>
    <div className="row">
      <div className="col-4">{governorate? governorate.name: ""}</div>
      <div className="col-4">{category ? category.name : ""}</div>
      <div className="col-4 nameField">
        {name}
        {icon ? (
          <img
            src={icon.grey}
            alt={`${category.name} logo`}
            className="icons d-none d-sm-block"
          />
        ) : (
          <img
            src={require("../extra/images/arrow-down.svg")}
            className="icons d-none d-sm-block"
            style={{ visibility: "hidden" }}
            alt="empty"
          />
        )}
      </div>
    </div>
  </div>
);}

export default FeatureCard;
