import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const FeatureModel = ({ isOpen, toggle, data, services, icons }) => {
  let service;
  if (data.category)
    service = services.filter((d) => d.id === data.category)[0];

  let icon = "";
  const filterit = icons.filter((i) => i.name === service.slug);

  if (filterit.length > 0) icon = filterit[0].yellow;

  return (
    <Modal size="lg" centered={true} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} dir="rtl" className="d-sm-none">
        {icon ? (
          <img src={icon} alt={`${service.name}`} className="icons" />
        ) : (
          <img className="icons" alt="empty" style={{ visibility: "hidden" }} />
        )}
      </ModalHeader>
      <ModalBody>
        <div className="col-12 col-lg-6 map">
          {data.location_latitude && data.location_longitude && (
            <a
              className="d-md-none btn btn-dark"
              href={`https://maps.google.com/maps?q=${data.location_latitude},${data.location_longitude}&hl=es;z=14&amp;&out`}
              target="blank"
            >
              Open maps
            </a>
          )}
          <i className="fa fa-map-marker d-none d-lg-block"></i>
          {data.location_latitude && data.location_longitude ? (
            <iframe
              src={`https://maps.google.com/maps?q=${data.location_latitude},${data.location_longitude}&hl=es;z=14&amp;&output=embed`}
              className="col-12 d-none d-md-block"
              height="100"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
              title="map"
            ></iframe>
          ) : (
            "لم يتحدد مكان"
          )}
        </div>
        <div className="col-12 col-lg-6">
          <div dir="rtl" className="row">
            <div className="d-none d-sm-block col-sm-1">
              {icon ? (
                <img src={icon} alt={`${service.name}`} className="icons" />
              ) : (
                <img
                  className="icons"
                  alt="empty"
                  style={{ visibility: "hidden" }}
                />
              )}
            </div>
            <div className="col-1"></div>
            <h3 className="col-sm-10">{data.name ? data.name : "ليس محدد"}</h3>
          </div>
          <p dir="rtl">
            <i className="fa fa-home" style={{ color: "grey" }}></i> العنوان :
            {data.address ? data.address : "ليس محدد"}
          </p>
          <p dir="rtl">
            <i className="fa fa-phone" style={{ color: "grey" }}></i> الهاتف:
            {data.telephone ? data.telephone : "ليس محدد"}
          </p>
          {data.organization && (
            <p dir="rtl">الجهة التابعة لها : {data.organization}</p>
          )}
          <div className="imgrow col-12 col-md-12">
            {data.image ? (
              <img
                src={data.image.url}
                alt="مركز الصحة الانجابية"
                className="col-12 col-md-8"
              />
            ) : (
              "لا توجد صورة"
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FeatureModel;
