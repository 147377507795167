import React, {useEffect, useState} from "react";
import InfoTabs from "../components/InfoTabs";
import More from "../components/More";
import { Spinner } from "reactstrap";


const Info = () => {
  const [infoTabs, setinfoTabs] = useState([])
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    fetch('https://api.youth.friendlyservices.org//wp-json/wp/v2/about')
    .then(resp => resp.json())
    .then(resp => setinfoTabs(resp.map(r => r.acf.text).reverse()))
    .then(setisloading(false))

  }, [])

  if (isloading && infoTabs.length) {
    return (
      <div
        className="row searchPage"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner type="grow" color="warning" />
        <Spinner type="grow" color="primary" />
        <Spinner type="grow" color="warning" />
      </div>
    );
  }
  
  return(
  <div className="row searchPage">
    <div className="container">
      <InfoTabs infoTabs={infoTabs}/>
      <More modals={infoTabs}/>
    </div>
  </div>
);}

export default Info;
