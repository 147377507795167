import React, { useState, useContext, useEffect } from "react";
import { Spinner } from "reactstrap";
import FeatureCards from "../components/FeatureCards";
import FeatureModel from "../components/FeatureModel";
import Searchbar from "../components/Searchbar";
import SearchContext from "../contexts/SearchContext";
import { baseUrl, Governorates, Categories, Icons } from "../extra/baseUrl";

const Search = (props) => {
  const [id, setId] = useState(0);
  const [modal, setModal] = useState(false);
  const [services, setservices] = useState([]);
  const [icons, seticons] = useState([]);
  const [governorates, setgovernorates] = useState([]);

  const [isLoading, setLoading] = useState(true);

  const [name, setName] = useState("");
  const [governorate, setgovernorate] = useState("");
  const [service, setservice] = useState("");
  const [country, setCountry] = useState([]);

  const [filter, setFilter] = useContext(SearchContext);

  const toggle = (e, id = -1) => {
    if (id !== -1) setId(id);
    setModal(!modal);
  };

  useEffect(() => {
    setName(filter.name);
    setservice(filter.service);
    setgovernorate(filter.governorate);
    setCountry(filter.country);


    Promise.all(
      [
        fetch(baseUrl + Categories).then(resp => resp.json()),
        fetch(baseUrl + Governorates).then(resp => resp.json()),
        fetch(baseUrl + Icons).then(resp => resp.json())
      ]
    )
    .then(([Categories, Governorates, Icons]) => {
      setservices(
        Categories.map((p) => ({
          id: p.id,
          name: p.name,
          slug: p.slug,
        }))
      );
      setgovernorates(
        Governorates.map((p) => ({
          id: p.id,
          name: p.name,
          slug: p.slug,
        }))
      );
      seticons(
        Icons.map((p) => ({
          name: p.acf.name,
          grey: p.acf.grey_icon.url,
          yellow: p.acf.yellow_icon.url,
        }))
      );
    }).then(setLoading(false));


  }, [filter, setFilter]);

  if ((isLoading && !props.data) || props.isLoading ) {
    return (
      <div
        className="row searchPage"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner type="grow" color="warning" />
        <Spinner type="grow" color="primary" />
        <Spinner type="grow" color="warning" />
      </div>
    );
  }
  return (
    <div className="searchPage">
      <Searchbar
        setFilter={setFilter}
        filter={filter}
        services={services}
        governorates={governorates}
        name={name}
        setName={setName}
        service={service}
        setservice={setservice}
        governorate={governorate}
        setgovernorate={setgovernorate}
      />
        <FeatureCards
          data={props.data}
          filter={{
            name,
            service,
            governorate,
            country
          }}
          toggle={toggle}
          services={services}
          governorates ={governorates}
          setFilter={setFilter}
          icons={icons}
        />
      {props.data.filter((d) => d.id === id)[0] && (
        <FeatureModel
          isOpen={modal}
          data={props.data.filter((d) => d.id === id)[0]}
          toggle={toggle}
          services={services}
          icons={icons}
        />
      )}
    </div>
  );
};

export default Search;
