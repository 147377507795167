import React from "react";
import { Input } from "reactstrap";
import Selectbox from "./Selectbox";
const FeatureCard = ({
  filter,
  setFilter,
  services,
  governorates,
  name,
  setName,
  service,
  setservice,
  governorate,
  setgovernorate,
}) => {
  return (
    <div className="container">
      <div className="col-11 col-sm-11 feature search ml-auto mr-auto searchbar">
        <div className="row">
          <div className="col-12 col-sm-6 col-lg-4 governorate">
            <Selectbox
              list={governorates}
              set={setgovernorate}
              placeholder={"المنطقة"}
              value={governorate}
              all={true}
            />
          </div>
          <div className="col-12 col-sm-6 col-lg-4 service">
            <Selectbox
              list={services}
              set={setservice}
              placeholder={"نوع الخدمة"}
              value={service}
            />
          </div>
          <div className="col-12 col-sm-6 col-lg-4">
            <Input
              type="text"
              name="text"
              id="exampleEmail"
              placeholder="اسم المكان"
              onChange={(e) => setName(e.target.value)}
              value={name}
              className="col-12 col-sm-10"
              dir="rtl"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
