import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className=" container">
        <div className="row">
          <a href="https://www.international-alert.org/" target="blank">
            <img
              src={require("../extra/images/yfs_alert.png")}
              alt="YFS alert"
            />
          </a>
          <div className="v-line d-none d-md-block"></div>
          <img
            src={require("../extra/images/yfs_footer.png")}
            alt="shamesya logo"
            style={{ objectFit: "contain" }}
          />
          <div className="v-line d-none d-md-block"></div>
          <a href="https://shamseya.org/" target="blank">
            <img
              src={require("../extra/images/footer3.png")}
              alt="حقي و حقك"
              style={{ objectFit: "contain" }}
            />
          </a>
        </div>
        <div className="row copyrights">
          {String.fromCharCode(169) + "2020 All rights reserved"}
        </div>
      </div>
    </div>
  );
};
export default Footer;
