import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const InfoModal = ({ isOpen, toggle, data, title, imgsrc}) => {

  return (
    <Modal size="lg" centered={true} isOpen={isOpen} toggle={toggle}  className="info-modals">
      <ModalHeader toggle={toggle} dir="rtl">
          <img src={imgsrc} alt="international alert logo" style={{height: 50, marginLeft: 20}}/>
          {title}
      </ModalHeader>
      <ModalBody
      dir="rtl"
      dangerouslySetInnerHTML = {
              {
                __html: data
              }
            }
            >
        
      </ModalBody>
    </Modal>
  );
};

export default InfoModal;
