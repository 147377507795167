import React from "react";

const Info = () => (
  <div className="row searchPage">
    <div className="container" style={{ alignSelf: "center" }}>
      <div className="row">
        <div className="col-sm-6" style={{ alignSelf: "center" }}>
          <img
            src={require("../extra/images/contact.png")}
            className="col-12"
            alt="illustration"
          />
        </div>
        <div className="col-sm-6 contact-us">
          <h1 dir="rtl" className="arabic contact-tag">
            اتصل بنا
          </h1>
          <div className="col-12 contact-group">
            <h4>International Alert, Tunisie</h4>
            <h6>
              Office C21, Bloc C, Residence Flamingo, Rue Lac Victoria, Berges
              du Lac, 1053, Tunis, Tunisie
            </h6>

            <div className="contact-row">
              <label htmlFor="tel" className="col-4">
                Telephone:
              </label>
              <a
                className="col-12 col-md-6"
                href="tel:+21671964905"
                target="blank"
                id="tel"
              >
                +216 71 964 905
              </a>
            </div>
            <div className="contact-row">
              <label htmlFor="Website1" className="col-4">
                Website:
              </label>
              <a
                className="col-12 col-md-6"
                href="https://www.international-alert.org"
                target="blank"
                id="Website1"
              >
                www.international-alert.org
              </a>
            </div>
          </div>
          <div className="col-12 contact-group">
            <h4>Shamseya For Innovative Community Healthcare Solutions</h4>
            <h6>6, Saad Zaghloul st, Al Sayeda Zainab, Cairo, Egypt.</h6>
            <div className="contact-row">
              <label htmlFor="Website" className="col-4">
                Website:
              </label>
              <a
                href="https://www.shamseya.org"
                target="blank"
                id="Website"
                className="col-12 col-md-6"
              >
                www.shamseya.org
              </a>
            </div>
          </div>
          <div className="col-12 contact-group">
            <h4 dir="rtl" className="arabic">
              الجمعية التونسية للدفاع عن المِرْفق العمومي للصحة وعن حقوق
              مستعمليه
            </h4>
            <h6>
              Association Tunisienne de Défense du Service Public de la Santé et
              des Droits de ses Usagers
            </h6>
            <h6 dir="rtl" className="arabic">
              إقامة الورود 4 ، الطابق الرابع، شقة عدد73، شارع محمد صالح بالحاج -
              2080 أريانة
            </h6>
            <div className="contact-row">
              <label htmlFor="Website" className="col-4">
                Telephone:
              </label>
              <div className="col-12 col-md-6">
                <a href="tel:97612767" target="blank" id="tel">
                  97612767
                </a>
                -
                <a href="tel:+58595044" target="blank" id="tel">
                  58595044
                </a>
                -
                <a href="tel:294613000" target="blank" id="tel">
                  294613000
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Info;
