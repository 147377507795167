import React, { useEffect, useState } from "react";
import SearchBox from "../components/SearchBox";
import { baseUrl, Governorates, Countries, Categories } from "../extra/baseUrl";
import { Spinner } from "reactstrap";

const Header = () => {
  const [governorate, setgovernorate] = useState([]);
  const [service, setservice] = useState([]);
  const [country, setcountries] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
        Promise.all(
          [
            fetch(baseUrl + Categories).then(resp => resp.json()),
            fetch(baseUrl + Governorates).then(resp => resp.json()),
            fetch(baseUrl + Countries).then(resp => resp.json())
          ]
        )
        .then(([Categories, Governorates, Countries]) => {
          setservice(
            Categories.map((p) => ({
              id: p.id,
              name: p.name,
              slug: p.slug,
            }))
          );
          setgovernorate(
            Governorates.map((p) => ({
              id: p.id,
              name: p.name,
              slug: p.slug,
            }))
          );
          setcountries(
            Countries.map((p) => ({
              id: p.id,
              name: p.name,
              slug: p.slug,
            }))
          );
        }).then(setLoading(false));
  }, []);

  if (isLoading) {
    return (
      <div
        className="row searchPage"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner type="grow" color="warning" />
        <Spinner type="grow" color="primary" />
        <Spinner type="grow" color="warning" />
      </div>
    );
  }

  return (
    <div className="row main">
      <div className="col-1"></div>
      <div className="col-xs-12 col-lg-4">
        <SearchBox governorates={governorate} services={service}  countries={country}/>
      </div>
      <div className="col-xs-12 col-lg-6 info-box">
        <h1>
          هنا خدمات
          <br />
          صديقة للشباب
        </h1>
      </div>
    </div>
  );
};

export default Header;
