import React from "react";

const Blogs = () => (
  <div className="row searchPage">
    <div
      className="container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        gap: 10,
      }}
    >
      <p style={{ fontSize: "24px" }}>مقترح مشروع مريڤل</p>
      <a
        className="btn-warning btn"
        href="https://drive.google.com/file/d/1fGQl1_j8YU1a6OVuvrA-B7JxR2hW4hPY/view?usp=share_link"
        target="_blank"
        rel="noopener noreferrer"
      >
        تنزيل
      </a>
      <iframe
        src="https://drive.google.com/file/d/1fGQl1_j8YU1a6OVuvrA-B7JxR2hW4hPY/preview?usp=share_link"
        width="100%"
        height="750px"
        title="viewable-report"
      ></iframe>
    </div>
  </div>
);

export default Blogs;
