import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";

const Example = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div dir="rtl" className="info">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            عن المشروع
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            اهداف المشروع
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} dir="rtl">
        <TabPane tabId="1" dir="rtl">
          <Row dir="rtl">
            < Col sm = "12"
            dir = "rtl"
            className = "project-goal"
            dangerouslySetInnerHTML = {
              {
                __html: props.infoTabs[0]
              }
            } >
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            < Col className = "project-target"
            dangerouslySetInnerHTML = {
              {
                __html: props.infoTabs[1]
              }
            } >
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Example;
