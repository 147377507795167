import React, { useState } from "react";
import InfoModal from "./InfoModal";

const More = ({modals}) => {
  const [shamseya, setshamseya] = useState(false);
  const [rights, setrights] = useState(false);
  const [international, setinternational] = useState(false);

  const toggleshamseya = (e) => {
    setshamseya(!shamseya);
  };
  const togglerights = (e) => {
    setrights(!rights);
  };
  const toggleinternational = (e) => {
    setinternational(!international);
  };

  return (
    <div className="row more">
      
      <div className="col-12" dir="rtl">
        <h4>اعرف المزيد عن</h4>
      </div>

      <div className="row" dir="rtl">
        <div className="col-md-4">
          <h5 onClick={toggleinternational}>
            انترناشونال الرت
          </h5>
        </div>
        <div className="col-md-4">
          <h5 onClick={toggleshamseya}>شمسية</h5>
        </div>
        <div className="col-md-4">
          <h5 onClick={togglerights}>حقي و حقك</h5>
        </div>
      </div>
                

      <InfoModal toggle={togglerights} isOpen={rights} title="حقي و حقك" imgsrc={require("../extra/images/footer3.png")} data={modals[2]} />
      <InfoModal toggle={toggleinternational} isOpen={international} title="انترناشونال الرت" imgsrc={require("../extra/images/yfs_alert.png")} data={modals[3]} />
      <InfoModal toggle={toggleshamseya} isOpen={shamseya} title="شمسیة" imgsrc={require("../extra/images/yfs_footer.png")} data={modals[4]}/>
    </div>
  );
};

export default More;
